import { default as _91_46_46_46slugs_93nkg1dABiAWMeta } from "/app/apps/bmwgroup/pages/[...slugs].vue?macro=true";
import { default as _91slug_93wLviXh7ojSMeta } from "/app/apps/bmwgroup/pages/auction/[slug].vue?macro=true";
import { default as _91slug_935fbCrJtdK7Meta } from "/app/apps/bmwgroup/pages/auction/current/[slug].vue?macro=true";
import { default as pdf6mnwY7SX5uMeta } from "/app/apps/bmwgroup/pages/cms/pdf.vue?macro=true";
import { default as previewZgsQkg8ZXcMeta } from "/app/apps/bmwgroup/pages/cms/preview.vue?macro=true";
import { default as detailsY8Hh41eZ95Meta } from "/app/apps/bmwgroup/pages/item/[slug]/details.vue?macro=true";
import { default as indexOKC96ZOqzpMeta } from "/app/apps/bmwgroup/pages/item/[slug]/index.vue?macro=true";
import { default as previewNCh11p5mRdMeta } from "/app/apps/bmwgroup/pages/item/[slug]/preview.vue?macro=true";
import { default as loginuOuqJPt0rIMeta } from "/app/apps/bmwgroup/pages/login.vue?macro=true";
export default [
  {
    name: "slugs___en",
    path: "/en/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___de___default",
    path: "/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___de",
    path: "/de/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___pl",
    path: "/pl/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___cs",
    path: "/cs/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___et",
    path: "/et/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___es",
    path: "/es/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___fr",
    path: "/fr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___hr",
    path: "/hr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___it",
    path: "/it/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___lv",
    path: "/lv/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___lt",
    path: "/lt/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___hu",
    path: "/hu/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___nl",
    path: "/nl/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___pt",
    path: "/pt/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___ro",
    path: "/ro/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___sk",
    path: "/sk/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___sl",
    path: "/sl/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___sr",
    path: "/sr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___tr",
    path: "/tr/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___el",
    path: "/el/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___bg",
    path: "/bg/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "slugs___ru",
    path: "/ru/:slugs(.*)*",
    meta: _91_46_46_46slugs_93nkg1dABiAWMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/[...slugs].vue")
  },
  {
    name: "auction-slug___en",
    path: "/en/auction/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___de___default",
    path: "/auktion/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___de",
    path: "/de/auktion/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___pl",
    path: "/pl/aukcja/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___cs",
    path: "/cs/aukce/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___et",
    path: "/et/oksjon/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___es",
    path: "/es/subasta/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___fr",
    path: "/fr/encheres/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___hr",
    path: "/hr/aukcija/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___it",
    path: "/it/asta/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___lv",
    path: "/lv/izsole/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___lt",
    path: "/lt/aukcione/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___hu",
    path: "/hu/arveres/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___nl",
    path: "/nl/veiling/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___pt",
    path: "/pt/leilao/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___ro",
    path: "/ro/licitatie/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___sk",
    path: "/sk/aukcie/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___sl",
    path: "/sl/drazba/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___sr",
    path: "/sr/aukcija/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___tr",
    path: "/tr/acik-arttirma/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___el",
    path: "/el/dhmoprasia/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___bg",
    path: "/bg/turg/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-slug___ru",
    path: "/ru/aukcion/:slug()",
    meta: _91slug_93wLviXh7ojSMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/[slug].vue")
  },
  {
    name: "auction-current-slug___en",
    path: "/en/auction/current/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___de___default",
    path: "/auktion/aktuell/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___de",
    path: "/de/auktion/aktuell/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___pl",
    path: "/pl/aukcja/aktualna/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___cs",
    path: "/cs/aukce/aktualni/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___et",
    path: "/et/oksjon/praegune/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___es",
    path: "/es/subasta/actual/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___fr",
    path: "/fr/encheres/actuel/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___hr",
    path: "/hr/aukcija/trenutno/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___it",
    path: "/it/asta/attuale/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___lv",
    path: "/lv/izsole/strava/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___lt",
    path: "/lt/aukcione/srove/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___hu",
    path: "/hu/arveres/jelenlegi/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___nl",
    path: "/nl/veiling/huidig/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___pt",
    path: "/pt/leilao/atual/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___ro",
    path: "/ro/licitatie/actual/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___sk",
    path: "/sk/aukcie/prud/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___sl",
    path: "/sl/drazba/trenutno/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___sr",
    path: "/sr/aukcija/trenutni/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___tr",
    path: "/tr/acik-arttirma/akim/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___el",
    path: "/el/dhmoprasia/reyma/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___bg",
    path: "/bg/turg/tekush/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "auction-current-slug___ru",
    path: "/ru/aukcion/tekushij/:slug()",
    meta: _91slug_935fbCrJtdK7Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/auction/current/[slug].vue")
  },
  {
    name: "cms-pdf___en",
    path: "/en/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___de___default",
    path: "/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___de",
    path: "/de/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___pl",
    path: "/pl/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___cs",
    path: "/cs/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___et",
    path: "/et/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___es",
    path: "/es/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___fr",
    path: "/fr/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___hr",
    path: "/hr/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___it",
    path: "/it/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___lv",
    path: "/lv/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___lt",
    path: "/lt/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___hu",
    path: "/hu/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___nl",
    path: "/nl/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___pt",
    path: "/pt/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___ro",
    path: "/ro/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___sk",
    path: "/sk/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___sl",
    path: "/sl/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___sr",
    path: "/sr/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___tr",
    path: "/tr/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___el",
    path: "/el/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___bg",
    path: "/bg/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-pdf___ru",
    path: "/ru/cms/pdf",
    meta: pdf6mnwY7SX5uMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/pdf.vue")
  },
  {
    name: "cms-preview___en",
    path: "/en/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___de___default",
    path: "/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___de",
    path: "/de/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___pl",
    path: "/pl/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___cs",
    path: "/cs/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___et",
    path: "/et/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___es",
    path: "/es/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___fr",
    path: "/fr/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___hr",
    path: "/hr/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___it",
    path: "/it/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___lv",
    path: "/lv/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___lt",
    path: "/lt/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___hu",
    path: "/hu/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___nl",
    path: "/nl/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___pt",
    path: "/pt/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___ro",
    path: "/ro/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___sk",
    path: "/sk/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___sl",
    path: "/sl/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___sr",
    path: "/sr/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___tr",
    path: "/tr/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___el",
    path: "/el/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___bg",
    path: "/bg/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "cms-preview___ru",
    path: "/ru/cms/preview",
    meta: previewZgsQkg8ZXcMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/cms/preview.vue")
  },
  {
    name: "item-slug-details___en",
    path: "/en/item/:slug()/details",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___de___default",
    path: "/artikel/:slug()/einzelheiten",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___de",
    path: "/de/artikel/:slug()/einzelheiten",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___pl",
    path: "/pl/przedmiot/:slug()/detale",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___cs",
    path: "/cs/polozka/:slug()/podrobnosti",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___et",
    path: "/et/uksus/:slug()/uksikasjad",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___es",
    path: "/es/articulo/:slug()/detalles",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___fr",
    path: "/fr/article/:slug()/details",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___hr",
    path: "/hr/artikal/:slug()/pojedinosti",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___it",
    path: "/it/articolo/:slug()/dettagli",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___lv",
    path: "/lv/lieta/:slug()/detalas",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___lt",
    path: "/lt/daiktas/:slug()/detales",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___hu",
    path: "/hu/tetel/:slug()/reszletek",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___nl",
    path: "/nl/item/:slug()/details",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___pt",
    path: "/pt/item/:slug()/detalhes",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___ro",
    path: "/ro/articol/:slug()/detalii",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___sk",
    path: "/sk/polozka/:slug()/podrobnosti",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___sl",
    path: "/sl/postavka/:slug()/podrobnosti",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___sr",
    path: "/sr/predmet/:slug()/detaljima",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___tr",
    path: "/tr/oge/:slug()/detaylar",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___el",
    path: "/el/eidos/:slug()/leptomeries",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___bg",
    path: "/bg/vesh/:slug()/podrobnosti",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug-details___ru",
    path: "/ru/element/:slug()/podrobnosti",
    meta: detailsY8Hh41eZ95Meta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/details.vue")
  },
  {
    name: "item-slug___en",
    path: "/en/item/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___de___default",
    path: "/artikel/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___de",
    path: "/de/artikel/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___pl",
    path: "/pl/przedmiot/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___cs",
    path: "/cs/polozka/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___et",
    path: "/et/uksus/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___es",
    path: "/es/articulo/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___fr",
    path: "/fr/article/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___hr",
    path: "/hr/artikal/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___it",
    path: "/it/articolo/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___lv",
    path: "/lv/lieta/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___lt",
    path: "/lt/daiktas/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___hu",
    path: "/hu/tetel/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___nl",
    path: "/nl/item/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___pt",
    path: "/pt/item/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___ro",
    path: "/ro/articol/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___sk",
    path: "/sk/polozka/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___sl",
    path: "/sl/postavka/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___sr",
    path: "/sr/predmet/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___tr",
    path: "/tr/oge/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___el",
    path: "/el/eidos/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___bg",
    path: "/bg/vesh/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug___ru",
    path: "/ru/element/:slug()",
    meta: indexOKC96ZOqzpMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/index.vue")
  },
  {
    name: "item-slug-preview___en",
    path: "/en/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___de___default",
    path: "/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___de",
    path: "/de/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___pl",
    path: "/pl/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___cs",
    path: "/cs/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___et",
    path: "/et/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___es",
    path: "/es/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___fr",
    path: "/fr/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___hr",
    path: "/hr/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___it",
    path: "/it/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___lv",
    path: "/lv/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___lt",
    path: "/lt/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___hu",
    path: "/hu/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___nl",
    path: "/nl/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___pt",
    path: "/pt/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___ro",
    path: "/ro/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___sk",
    path: "/sk/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___sl",
    path: "/sl/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___sr",
    path: "/sr/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___tr",
    path: "/tr/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___el",
    path: "/el/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___bg",
    path: "/bg/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "item-slug-preview___ru",
    path: "/ru/item/:slug()/preview",
    meta: previewNCh11p5mRdMeta || {},
    component: () => import("/app/apps/bmwgroup/pages/item/[slug]/preview.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___de___default",
    path: "/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___de",
    path: "/de/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___pl",
    path: "/pl/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___cs",
    path: "/cs/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___et",
    path: "/et/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___es",
    path: "/es/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___hr",
    path: "/hr/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___it",
    path: "/it/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___lv",
    path: "/lv/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___lt",
    path: "/lt/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___hu",
    path: "/hu/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___nl",
    path: "/nl/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___pt",
    path: "/pt/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___ro",
    path: "/ro/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___sk",
    path: "/sk/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___sl",
    path: "/sl/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___sr",
    path: "/sr/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___tr",
    path: "/tr/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___el",
    path: "/el/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___bg",
    path: "/bg/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  },
  {
    name: "login___ru",
    path: "/ru/login",
    component: () => import("/app/apps/bmwgroup/pages/login.vue")
  }
]