<template>
  <CommonDialog
    :opened="isOpen"
    :main-props="{ class: '!overflow-visible' }"
    @close="$emit('close')"
  >
    <template #title>
      <div class="flex flex-col normal-case">
        <h2 class="text-2xl">{{ $t('auction-note-modal.title') }}</h2>
        <h3 class="font-sans font-normal">
          {{ carInfo }}
        </h3>
      </div>
    </template>
    <template #content>
      <div class="relative flex w-full flex-col justify-end overflow-visible">
        <textarea
          v-model="text"
          :placeholder="$t('auction-note-modal.input-placeholder')"
          :class="[FIELD_INNER_STYLES, isInvalid ? '!border-red' : '']"
          class="h-24 resize-none"
        />
        <div
          class="my-4 ml-auto block w-max"
          :class="isInvalid ? 'text-red' : ''"
        >
          {{ text.length }}/{{ MAX_LENGTH }}
        </div>
        <CommonTooltip
          class="!ml-auto"
          :tooltip="
            !isInvalid
              ? isError
                ? $t('auction-note-modal.retry-saving-note')
                : $t('auction-note-modal.save-note')
              : $t('auction-note-modal.note-is-too-long')
          "
        >
          <button
            :aria-label="
              !isInvalid
                ? isError
                  ? $t('auction-note-modal.retry-saving-note')
                  : $t('auction-note-modal.save-note')
                : $t('auction-note-modal.note-is-too-long')
            "
            class="group text-primary disabled:cursor-not-allowed disabled:text-slate-200/50"
            :disabled="isInvalid || isLoading"
            @click="() => mutate(!text.length)"
          >
            <Spinner v-if="isLoading && !isError" class="!h-[32px] !w-[32px]" />
            <Icon
              v-else-if="!isLoading && isError"
              mode="svg"
              name="solar:restart-bold"
              size="32"
            />
            <Icon v-else mode="svg" name="material-symbols:save" size="32" />
          </button>
        </CommonTooltip>
        <button
          v-if="isNoteLoadError"
          class="text-orange-400"
          @click="() => refetch()"
        >
          {{ $t('auction-note-modal.reload-note') }}
        </button>
      </div>
    </template>
  </CommonDialog>
</template>

<script setup lang="ts">
import { FIELD_INNER_STYLES } from '@autobid/ui/constants/FIELD_INNER_STYLES'
import { useMutation } from '@tanstack/vue-query'
import Spinner from '@autobid/ui/components/common/Spinner.vue'
import { getError } from '@autobid/strapi-integration/utils/getError'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { useNote } from '@autobid/ui/composables/car/useNote'

type Props = {
  carInfo: string
  isOpen: boolean
  carId: number
  initialNote?: string
  hasNote?: boolean
}

const MAX_LENGTH = 200

const props = defineProps<Props>()
const emits = defineEmits<{
  (e: 'close'): void
  (e: 'update', hasNote: boolean): void
}>()
const { $customFetch } = useCustomFetch()

const { locale, t } = useI18n()

const {
  note,
  isError: isNoteLoadError,
  refetch
} = useNote(props.carId, {
  enabled: props.hasNote && props.isOpen,
  onSuccess: (v) => {
    text.value = v.data.note
  }
})
const text = ref(note.value?.data.note ?? '')

const push = usePush()

const { isLoading, isError, mutate } = useMutation({
  mutationFn: (remove: boolean) => {
    return $customFetch('/api/backend', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        queryMethod: !remove ? 'POST' : 'DELETE',
        queryApi: 'webapi',
        headers: {
          'Accept-Language': locale.value
        },
        queryUrl: `/v1/cars/${props.carId}/note`,
        queryFormData: { note: text.value }
      }
    })
  },
  onError: (error) => {
    push.error(getError(error).message)
  },
  onSuccess: (_, remove) => {
    push.success(t('auction-note-modal.note-has-been-saved'))
    emits('update', !remove)
  }
})

const isInvalid = computed(() => text.value.length > MAX_LENGTH)
</script>
