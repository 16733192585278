import revive_payload_client_YTGlmQWWT5 from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_xKuw1km5sV from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/bmwgroup/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/app/apps/bmwgroup/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _001_notivue_client_bE2FHKqHiu from "/app/apps/bmwgroup/.nuxt/001.notivue.client.mjs";
import plugin_client_vHP6egnfXh from "/app/packages/tracking/src/plugin.client.ts";
import plugin_client_hMfgTJD6If from "/app/packages/web-socket/src/plugin.client.ts";
import plugin_client_1VwdI16TrR from "/app/packages/sentry/src/plugin.client.ts";
import plugin_j3xPeZV1re from "/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_8brYH9QEed from "/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/app/apps/bmwgroup/.nuxt/formkitPlugin.mjs";
import vue_query_83HKOxoZiY from "/app/packages/ui/plugins/vue-query.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  i18n_yfWm7jX06p,
  _001_notivue_client_bE2FHKqHiu,
  plugin_client_vHP6egnfXh,
  plugin_client_hMfgTJD6If,
  plugin_client_1VwdI16TrR,
  plugin_j3xPeZV1re,
  plugin_8brYH9QEed,
  formkitPlugin_pZqjah0RUG,
  vue_query_83HKOxoZiY
]