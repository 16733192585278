<template>
  <div id="content" class="w-full">
    <transition
      enter-active-class="transition duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-1"
      leave-active-class="transition duration-300"
      leave-from-class="opacity-1"
      leave-to-class="opacity-0"
      mode="out-in"
    >
      <div :key="currentCar?.id" class="w-full">
        <template v-if="currentCar">
          <p
            v-if="currentCar.lowBudgetWarning"
            class="mt-3 px-3 font-bold text-red xl:px-0"
          >
            <span class="block">
              {{ $t('barometer.low-budget-warning-heading') }}
            </span>
            {{ $t('barometer.low-budget-warning-desc') }}
          </p>
          <BarometerPanelDesktop
            :car="currentCar"
            :time-remaining="timeRemaining.full"
            :is-countdown-over="isCountdownOver"
            :title="title"
            @show-all-bids="showAllBidsDialog = true"
          />
          <BarometerPanelMobile
            :car="currentCar"
            :time-remaining="timeRemaining.full"
            :is-countdown-over="isCountdownOver"
            :title="title"
            @show-all-bids="showAllBidsDialog = true"
          />
          <GOFT
            v-if="currentCar.goft"
            :is-open="showGOFTSplash"
            :car-id="currentCarId"
            @close.once="router.back"
            @confirm="showGOFTSplash = false"
          />
        </template>
        <SectionsBarometerFinishPageRedirector
          v-else-if="!loadingNewCars"
          :finish-auction-url="finishAuctionUrl"
        />
      </div>
    </transition>

    <BarometerAllBids
      v-if="currentCar"
      :key="currentCarId"
      :car-id="currentCarId"
      :active="showAllBidsDialog"
      @close="showAllBidsDialog = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useCarsStore } from '@autobid/nuxt-pinia-store/store/useCarsStore'
import { storeToRefs } from 'pinia'
import type { StrapiPageMetaData } from '@autobid/strapi-integration/typescript/strapi/Page'
import { computed, inject, onMounted, provide, ref, watch } from 'vue'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { useCarCountdown } from '@autobid/ui/composables/car/countdown/useCarCountdown'
import { useCurrentAuctionState } from '@autobid/ui/composables/car/useCurrentAuctionState'
import type { BarometerProps } from '@autobid/ui/types/components/BarometerProps'
import { useAuction } from '@autobid/ui/composables/useAuction'
import { STRAPI_SEO_INJECTION_KEY } from '@autobid/ui/constants/STRAP_SEO_INJECTION_KEY'
import { useCarSeoMeta } from '@autobid/ui/composables/car/useCarSeoMeta'
import { useCarGoft } from '@autobid/ui/composables/car/useCarGoft'
import { GOFT_PAGE_ID } from '@autobid/ui/constants/GOFT_PAGE_ID'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import GOFT from '@autobid/ui/components/common/dialog/GOFT.vue'
import type { AuctionItemProps } from '@autobid/ui/types/components/AuctionItem'
import { useOpenLink } from '@autobid/ui/composables/useOpenLink'
import { useCar } from '@autobid/ui/composables/useCar'
import { useCarSwipe } from '@autobid/ui/composables/useCarSwipe'
import BarometerPanelMobile from './BarometerPanelMobile.vue'
import BarometerPanelDesktop from './BarometerPanelDesktop.vue'
import BarometerAllBids from './BarometerAllBids.vue'

const { loadingNewCars, auctionId } = storeToRefs(useCarsStore())
const props = defineProps<BarometerProps>()
const { finishAuctionUrl } = inject<AuctionItemProps>('auction-item-props')
const { $customFetch } = useCustomFetch()
const { open } = useOpenLink()
const { data: currentCar } =
  props.type === 'current'
    ? useCar({ type: 'current', auctionId: auctionId.value })
    : useCar({ type: 'barometer', carId: props.carId })

const carInAuctionInstance =
  props.type === 'item'
    ? useCar({
        type: 'current',
        auctionId: auctionId.value
      })
    : null

const currentCarId = computed(() => currentCar.value?.id)
useCarSwipe(currentCarId)

const { auction } = useAuction(
  props.type === 'current' ? props.auctionId : currentCar.value?.auctionId
)
const showAllBidsDialog = ref(false)

const state = useCurrentAuctionState(currentCar)

provide('state', state)
provide('barometerType', props.type)

const title = computed(() => {
  if (currentCar.value.stage !== 'FINISHED' && !currentCar.value.bids.length)
    return 'shared-car.place-bid-now'

  if (state.value.isSold && currentCar.value.stage === 'FINISHED') {
    return 'shared-car.sold'
  }
  if (state.value.isNotSold) {
    return 'shared-car.not_sold'
  }
  if (
    !state.value.isSold &&
    (state.value.isUnderReservation || state.value.isUnderAuctionReservation)
  ) {
    return 'shared-car.reserved'
  }

  return 'shared-car.released'
})

const { timeRemaining, isCountdownOver } = useCarCountdown(currentCar)

const strapiSeo: StrapiPageMetaData | null = inject(
  STRAPI_SEO_INJECTION_KEY,
  null
)

useCarSeoMeta(strapiSeo, currentCar, auction)

const goft = useCarGoft(currentCarId)
const showGOFTSplash = ref(false)
const router = useRouter()
const { isAuthed } = useAutobidAuth()

const handleGOFT = async () => {
  const shouldShowGOFTSplash = Boolean(currentCar.value?.equipments.eq1157)

  if (!shouldShowGOFTSplash || !isAuthed.value || !goft.value) return

  const isAccepted: { data: { accepted: boolean } } = await $customFetch(
    '/api/backend',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        queryApi: 'webapi',
        queryUrl: `/v1/cars/${currentCarId.value}/splashpageaccept/${GOFT_PAGE_ID}`
      }
    }
  )

  if (isAccepted.data.accepted) return

  showGOFTSplash.value = true
}

onMounted(() => {
  handleGOFT()

  if (auction.value.stage !== 'FINISHED' && carInAuctionInstance) {
    carInAuctionInstance.prefetchCar()
  }
})

watch(currentCarId, () => {
  if ('auctionId' in props && props.auctionId !== auctionId.value) return

  if (finishAuctionUrl && !currentCarId.value) {
    open(finishAuctionUrl)
  }
})
watch(currentCar, handleGOFT)
</script>
