import { onMounted, onBeforeUnmount } from 'vue'

interface UseSwipe {
  onSwipe: (direction: 'left' | 'right') => void
}

export const useSwipe = ({ onSwipe }: UseSwipe) => {
  if (typeof document === 'undefined') return

  const { body } = document

  let shouldBeSwiped = false
  let touchStartX = 0
  let touchEndX = 0
  let touchStartY = 0
  let touchEndY = 0

  const handleSwipe = () => {
    if (!shouldBeSwiped) return

    const THRESHOLD = 100
    const distanceX = touchEndX - touchStartX
    const distanceY = Math.abs(touchEndY - touchStartY)

    if (distanceY >= Math.abs(distanceX)) return

    if (distanceX < -THRESHOLD && distanceY < THRESHOLD) {
      // Swipe left
      onSwipe('left')
    } else if (distanceX > THRESHOLD && distanceY < THRESHOLD) {
      // Swipe right
      onSwipe('right')
    }
  }

  const validateTouchedElement = (event: TouchEvent) => {
    const { nodeName } = event.target as unknown as { nodeName: string }

    if (nodeName === 'INPUT' || nodeName === 'TEXTAREA') {
      return false
    }

    const invalidElements = [
      'swiper-container',
      '.lg-container',
      '.swiper-wrapper'
    ]
    const hasInvalidElement =
      'closest' in event.target &&
      invalidElements.find((el) => event.target.closest(el))

    if (hasInvalidElement) {
      return false
    }

    return true
  }

  const isScrollable = (element: HTMLElement): boolean => {
    const scrollable =
      element.classList.contains('overflow-auto') ||
      element.classList.contains('overflow-x-auto')

    if (scrollable) {
      return true
    }

    const parent = element.parentElement

    if (!parent || parent.tagName === 'BODY') return false

    return isScrollable(element.parentElement)
  }

  const onTouchStart = (event: TouchEvent) => {
    const isValidNodeName = validateTouchedElement(event)
    const scrollable = isScrollable(event.target as HTMLElement)

    if (!isValidNodeName || scrollable) {
      shouldBeSwiped = false
      return
    }

    shouldBeSwiped = true
    touchStartX = event.touches[0].clientX
    touchStartY = event.touches[0].clientY
  }
  const onTouchEnd = (event: TouchEvent) => {
    touchEndX = event.changedTouches[0].clientX
    touchEndY = event.changedTouches[0].clientY
    handleSwipe()
  }

  onMounted(() => {
    body.addEventListener('touchstart', onTouchStart)
    body.addEventListener('touchend', onTouchEnd)
  })

  onBeforeUnmount(() => {
    body.removeEventListener('touchstart', onTouchStart)
    body.removeEventListener('touchend', onTouchEnd)
    touchStartX = touchStartY = touchEndX = touchEndY = 0
  })
}
