<template>
  <div class="flex w-full flex-col gap-4 p-4 text-lg text-gray-500">
    <div class="flex w-full justify-between">
      <h3
        class="inline-flex w-full justify-between text-xl leading-6 !text-gray-500"
      >
        <span>{{ $t('barometer.current-bid') }}</span>
        <CommonCalculatedPrice
          v-if="isAuthed"
          class="text-left"
          :class="getColorBasedOnState(car, state, car.price.current, 'text')"
          :price="car.price.current"
        />
        <span v-else>{{ $t('shared-car.confidential') }}</span>
      </h3>
    </div>

    <SectionsBarometerBidAgentInfo
      v-if="isAuthed && car.stage !== 'FINISHED'"
      :car="car"
    />

    <div class="flex w-full flex-col">
      <h3 class="inline-flex w-full justify-between text-sm !text-gray-500">
        <span>{{ $t('barometer.call-price') }}</span>
        <CommonCalculatedPrice class="text-left" :price="car.price.start" />
      </h3>
      <div class="text-right text-xs underline">
        <ElementsCarVatLink
          class="inline-block self-start text-[10px] underline"
          :car="car"
          :tax-popup-slug="taxPopupSlug"
        />
      </div>
    </div>

    <ElementsCarFreeReservationButton :car="car" />
  </div>
</template>

<script setup lang="ts">
import type { BarometerCar } from '@autobid/ui/types/Car'
import type { AuctionState } from '@autobid/ui/types/AuctionState'
import { getColorBasedOnState } from '@autobid/ui/utils/car/getColor'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'

type Props = {
  car: BarometerCar
  taxPopupSlug: string
}

defineProps<Props>()

const state: AuctionState = inject('state')
const { isAuthed } = useAutobidAuth()
</script>
