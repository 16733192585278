import { computed, unref, type MaybeRef } from 'vue'
import { useGetCar } from '@autobid/ui/composables/useCar'
import type { BarometerCar, DetailsCar } from '@autobid/ui/types/Car'
import { useBodyStore } from '@autobid/nuxt-pinia-store/store/useBodyStore'
import { storeToRefs } from 'pinia'
import { useGetCarLink } from './useGetCarLink'

export function useCarPaginationLinks(
  carId: MaybeRef<number>,
  type: 'details' | 'barometer'
) {
  const { resolution } = storeToRefs(useBodyStore())
  const isMobile = computed(() => resolution.value.width < 768)
  const { getCar } = useGetCar()
  const pagination = computed(() => {
    const car = getCar(unref(carId)) as BarometerCar | DetailsCar | undefined

    if (!car) return

    return car.pagination
  })

  const { getLink } = useGetCarLink()

  const prevLink = computed(() => {
    const link = getLink(unref(pagination)?.previous, type)

    if (!link) return

    return [
      link,
      '?pageType=prev',
      isMobile.value && type === 'barometer' ? '#content' : ''
    ].join('')
  })

  const nextLink = computed(() => {
    const link = getLink(unref(pagination)?.next, type)

    if (!link) return

    return [
      link,
      '?pageType=next',
      isMobile.value && type === 'barometer' ? '#content' : ''
    ].join('')
  })

  return {
    pagination,
    nextLink,
    prevLink
  }
}
