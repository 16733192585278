<template>
  <CommonLink
    :href="detailsUrl"
    class="relative flex items-center justify-center"
    :class="{ 'bg-black': imageToDisplay }"
  >
    <CommonPicture
      v-if="imageToDisplay"
      v-bind="$attrs"
      :images="imageToDisplay.links"
      :sizes="THUMBNAIL_SIZES"
      default-size="l"
      class="h-auto max-h-[240px] w-full max-w-full object-contain sm:max-h-[288px] md:max-h-[384px] lg:max-h-[292px] [@media(max-width:440px)]:max-h-[162px]"
      :alt="imageToDisplay.description.categoryLabel"
    />
    <ElementsCarThumbnailPlaceholder v-else />

    <FinishedImageOverlay :car="car" :state="state" size="big" />
  </CommonLink>
</template>

<script setup lang="ts">
import FinishedImageOverlay from '@autobid/ui/components/elements/car/FinishedImageOverlay.vue'
import { useCurrentAuctionState } from '@autobid/ui/composables/car/useCurrentAuctionState'
import { useAuctionPagesLink } from '@autobid/ui/composables/useAuctionPagesLink'
import { useCar } from '@autobid/ui/composables/useCar'
import { computed } from 'vue'

type Props = {
  carId: number
}

const props = defineProps<Props>()
const { data: car } = useCar({ type: 'barometer', carId: props.carId })
const imageToDisplay = computed(
  () => Object.values(car.value?.imageGroups?.itemMainImageSubgroup ?? {})[0]
)
const state = useCurrentAuctionState(car)
const { getLink } = useAuctionPagesLink()

const detailsUrl = computed(() =>
  getLink({
    page: 'details',
    slug: car.value.slug
  })
)

const THUMBNAIL_SIZES = {
  l: 1279
}
</script>
