<template>
  <div class="flex justify-between text-sm text-gray-500 lg:mt-2 lg:flex-col">
    {{ $t('barometer.bid-agent') }}:

    <transition
      enter-active-class="transition duration-300"
      enter-from-class="scale-125 text-primary"
      enter-to-class="scale-100 text-inherit"
      leave-active-class="transition duration-300"
      leave-from-class="scale-100 text-inherit"
      leave-to-class="scale-125 text-primary"
      mode="out-in"
    >
      <span
        :key="`${car.ownBidAgentAmount}-${
          isAgentActive ? 'active' : 'inactive'
        }`"
        class="lg:font-semibold"
      >
        <CalculatedPrice v-if="isAgentActive" :price="car.ownBidAgentAmount" />
        <span v-else :key="car.ownBidAgentAmount">
          {{ $t('barometer.bid-agent-not-active') }}
        </span>
      </span>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useWebSocketStore } from '@autobid/nuxt-pinia-store/store/useWebSocketStore'
import CalculatedPrice from '@autobid/ui/components/common/CalculatedPrice.vue'
import type { BarometerCar } from '@autobid/ui/types/Car'
import { computed, onBeforeUnmount, onMounted } from 'vue'
import type { EventData } from '@autobid/ui/composables/car/useCarSockets/events/useAgentSet'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'

type Props = {
  car: BarometerCar
}

const props = defineProps<Props>()

const { startListen, endListen } = useWebSocketStore()
const { sessionData, isAuthed } = useAutobidAuth()
const { t } = useI18n()
const push = usePush()
const notifications = useNotifications()
const isAgentActive = computed(
  () => props.car.ownBidAgentAmount > props.car.price.current
)

const listenTheEvent = () => {
  if (!isAuthed.value) return

  startListen('premium.AGENT_SET', 'component', ({ context }: EventData) => {
    if (
      sessionData.value?.user?.id !== context.userId ||
      context.carId !== props.car.id
    ) {
      return
    }

    const wasAlreadyNotified = notifications.entries.value?.find(
      (el) => el.props.internalId === 'setAgent'
    )

    if (wasAlreadyNotified) return

    push.success(t('barometer.bid-agent-hired-socket-info'))
  })
}

onMounted(() => {
  listenTheEvent()
})

onBeforeUnmount(() => {
  endListen('premium.AGENT_SET', 'component')
})
</script>
