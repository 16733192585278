import type { MaybeRef } from 'vue'
import { computed, onMounted } from 'vue'
import { useCarPaginationLinks } from '@autobid/ui/composables/car/useCarPaginationLinks'
import { useSwipe } from '@autobid/ui/composables/useSwipe'
import { getPageType } from '@autobid/ui/composables/car/useCarPageInfo'
import { usePage } from '@autobid/strapi-integration/composable/usePage'

export const useCarSwipe = (carId: MaybeRef<number>) => {
  const route = useRoute()
  const router = useRouter()
  const pageType = getPageType(route.name)
  const { prefetchPage } = usePage()

  if (
    pageType !== 'details' &&
    pageType !== 'barometer' &&
    pageType !== 'barometerCurrent'
  ) {
    return
  }

  const data = useCarPaginationLinks(
    carId,
    pageType === 'barometerCurrent' ? 'barometer' : pageType
  )

  const prefetchBarometerPage = () => {
    if (
      pageType !== 'barometerCurrent' ||
      (!data.prevLink.value && !data.nextLink.value)
    ) {
      return
    }

    prefetchPage({
      slug: 'item',
      draft: false,
      allowEmpty: false,
      ignoreBlacklist: true
    })
  }

  useSwipe({
    onSwipe: (direction) => {
      if (direction === 'left' && data.nextLink.value) {
        router.push(data.nextLink.value)
      } else if (direction === 'right' && data.prevLink.value) {
        router.push(data.prevLink.value)
      }
    }
  })

  onMounted(() => {
    prefetchBarometerPage()
  })

  return data
}
