<template>
  <div v-if="pagination" class="flex w-full items-center justify-between">
    <CommonLink
      :href="prevLink"
      :aria-label="$t('pagination.prev-page')"
      :class="paginationCva({ type: 'previous', disabled: !prevLink })"
      :replace="true"
      @mouseenter="prefetch('prev')"
    >
      <Icon mode="svg" name="mingcute:left-line" class="z-10 text-2xl" />
    </CommonLink>
    <span
      class="px-3 py-1 text-center text-xl text-black text-primary md:px-10 xl:text-2xl"
    >
      <span>{{ $t('barometer.catalog-number') }}:</span>
      {{ catalogNumber }}
    </span>
    <CommonLink
      :href="nextLink"
      :class="paginationCva({ type: 'next', disabled: !nextLink })"
      :aria-label="$t('pagination.next-page')"
      :replace="true"
      @mouseenter="prefetch('next')"
    >
      <Icon mode="svg" name="mingcute:right-line" class="z-10 text-2xl" />
    </CommonLink>
  </div>
</template>

<script lang="ts" setup>
import type { CarPaginationProps } from '@autobid/ui/types/components/CarPaginationProps'
import { useCarPaginationLinks } from '@autobid/ui/composables/car/useCarPaginationLinks'
import { paginationCva } from '@autobid/ui/utils/paginationCva'
import { usePrefetchPagination } from '@autobid/ui/composables/car/usePrefetchPagination'

const props = defineProps<CarPaginationProps>()

const { pagination, prevLink, nextLink } = useCarPaginationLinks(
  props.id,
  props.type
)

const { prefetch } = usePrefetchPagination(pagination)
</script>
